<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <div>
                <CRow>
                    <CCol lg="5">
                        <CCard>
                            <CCardHeader><font-awesome-icon icon="list-ul"/>  New Department</CCardHeader>
                            <CCardBody>
                                <CForm @submit.prevent="createDepartment">
                                    <CRow>
                                        <CCol lg="12">
                                            <CInput 
                                                :lazy="false"
                                                :value.sync="$v.department.name.$model"
                                                :isValid="checkIfValid('name')"
                                                :key="group_key"
                                                label="Name" 
                                                type="text" 
                                                placeholder="Human Resources" 
                                                autocomplete="off"  
                                                v-model="department.name"
                                                invalidFeedback="Must be more than three(3) characters."
                                                v-tocapitalize
                                                v-nospecialcharacter
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="12">
                                            <CInput 
                                                readonly
                                                placeholder="John Doe" 
                                                label="Department Head" 
                                                v-model="department.head_name"
                                            >
                                                <template #append>
                                                    <CButton color="primary" @click="$refs.usersModal.users_modal = true, emitUserModal()">
                                                        
                                                        <font-awesome-icon icon="search"/>
                                                    </CButton>
                                                </template>
                                            </CInput>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="12">
                                            <CTextarea
                                                label="Description"
                                                placeholder="Content..."
                                                autocomplete="off"  
                                                rows="3"
                                                v-model="department.description"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol col="12" class="text-right">
                                            <CButton 
                                                :disabled="!isValid"
                                                type="submit" 
                                                shape="pill" 
                                                color="primary"
                                            >
                                                <font-awesome-icon icon="save"/> Save
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCol>
                    <CCol lg="7">
                        <CCard>
                            <CCardHeader><font-awesome-icon icon="list-ul"/>  Groups</CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol lg="10" md="10" sm="10">
                                        <v-select 
                                            label="name"
                                            v-model="selected_group"
                                            :options="groups_list"
                                            :class="!group_required ? 'has-error' : 'has-success'"
                                        />
                                    </CCol>
                                    <CCol lg="2" md="2" sm="2">
                                        <CButton    color="primary" 
                                                    @click="addGroup()"
                                                    :disabled="!group_required">
                                            <font-awesome-icon icon="plus"/>
                                        </CButton>
                                    </CCol>
                                    <br>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="department.groups"
                                            :fields="groups_list_fields"
                                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                            :items-per-page="5"
                                            border
                                            items-per-page-select
                                            sorter
                                            pagination
                                        >
                                            <template #icon="{item}">
                                                <td style="width: 10%;">
                                                    <center>
                                                        <CIcon :name="item.icon" size="xl" />
                                                    </center>
                                                </td>
                                            </template>
                                            <template #action="{item}">
                                                <td style="width: 10%;" >
                                                    <center>
                                                        <label v-if="item.general == '1'"> <i>Default</i> </label>
                                                        <CButton 
                                                            v-if="item.general != '1'"
                                                            color="danger" 
                                                            @click="removeGroup(item)" 
                                                            shape="pill" 
                                                            size="sm"
                                                        >
                                                            <font-awesome-icon icon="times"/>
                                                        </CButton>
                                                    </center>
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CCol>
                                
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </div>
            <UsersModal ref="usersModal" @selectedUser="userSelected($event)"/>
        </div>
    </div>
</template>

<script>
import UsersModal from '../../modals/UsersModal'
import { required, minLength} from "vuelidate/lib/validators"
export default {
    name: 'DepartmentCreate',
    components: {
        UsersModal,
    },
    data () {
        return {
            group_key: 0,
            group_required: false,
            groups_list: [],
            groups_list_fields: [
                { key: 'icon',},
                { key: 'name' },
                { key: 'action' },
            ],
            selected_group: null,
            user_heads_modal: false,
            heads_list: [],
            heads_list_fields: [
                { key: 'user_id', label: 'User ID'},
                { key: 'name' },
            ],
            department: {
                name: '',
                head_name: '',
                head_id: '',
                description: '',
                groups: [],
                group_ids: [],
            }
        }
    }, 
    computed: {
        isValid () { return !this.$v.department.$invalid },
        isDirty () { return this.$v.department.$anyDirty },
    },
    created() {
        this.validate();
        this.getGroups();
        this.$emit('activeTab', 1);
    },
    validations: { // FORM Validation (Vue)
        department: {
            name: {
                required, minLength: minLength(3)
            },
            group_ids: {
                required, minLength: minLength(1)
            }
        }
    },
    methods: {
        userSelected: function (object) {
            this.department.head_name = object.name
            this.department.head_id = object.id;
        },
        emitUserModal: function () {
            this.$emit('show_users_modal');
        }, 
        validate: function () {
            this.$v.$touch()
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.department[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validateForm:function () // JS Validation
        {
            if(
                !this.department.name
                ) return false;
            return true;
        },
        createDepartment: function () {
            if(this.validateForm()){
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to create new department.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return axios.post('/departments/store', this.department, {validateStatus: () => true}).then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${this.department.name} has been added.`,
                                })
                                this.department = this.resetForm();
                                this.$v.$reset()
                                this.$v.$touch()
                                this.getGroups();
                                this.$Progress.finish()
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            return;
        },
        resetForm: function () {
            return  {
                name: "",
                description: "",
                head_id: "",
                head_name: "",
                groups: [],
                group_ids: [],
            }
        },
        getGroups: function() {
            axios.get('/groups/active-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            general: response.data.data[i].general,
                            icon: response.data.data[i].icon,
                            name: response.data.data[i].name
                        }

                        this.groups_list.push(option)

                        if(response.data.data[i].general == '1') {
                            let default_row = {
                                id: response.data.data[i].id,
                                general: response.data.data[i].general,
                                icon: response.data.data[i].icon,
                                name: response.data.data[i].name,
                            }
                            this.department.groups.push(default_row)
                            this.department.group_ids.push(response.data.data[i].id)
                        }
                    }
                }
            })
        },
        addGroup:function() {
            this.group_key++;
            if(this.department.group_ids.includes(this.selected_group.id)) {
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Group already exists in the lists.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            } else {
                let row = {
                    id: this.selected_group.id,
                    general: this.selected_group.general,
                    icon: this.selected_group.icon,
                    name: this.selected_group.name,
                }
                this.department.groups.push(row)
                this.department.group_ids.push(this.selected_group.id)
                this.selected_group = null;
            }
        },
        removeGroup: function (item) {
            this.group_key++;
            for(let i = 0; i < this.department.groups.length; i++) {
                if(this.department.groups[i].name == item.name) {
                    this.department.groups.splice(i, 1)
                    break;
                }
            }
            this.department.group_ids = this.department.group_ids.filter(e => e !== item.id)
        }
    },
    watch: {
        selected_group: function (value) {
            this.group_required = value ? true : false
        }
    }
}
</script>